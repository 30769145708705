import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import React from "react";

import { Form, Dropdown } from "react-bootstrap";

import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";

import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";
import { EChartsOption } from "echarts-for-react";

export default function MedianAskMonthChart({
  chartData,
  filters,
  defaultMuni = "",
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  defaultMuni: string;
}) {
  const [bostonData, setBostonData] = useState<(number | Date)[][]>([]);
  const [cambridgeData, setCambridgeData] = useState<(number | Date)[][]>([]);
  const [arlingtonData, setArlingtonData] = useState<(number | Date)[][]>([]);
  const [somervilleData, setSomervilleData] = useState<(number | Date)[][]>([]);
  const [quincyData, setQuincyData] = useState<(number | Date)[][]>([]);

  const [selectedMunis, setSelectedMunis] = useState<string[]>(defaultMuni !== "" ? [defaultMuni] : municipalities);

  useEffect(() => {
    // add data to chart data array
    let tempBostonSeries: (number | Date)[][] = [];
    let tempQuincySeries: (number | Date)[][] = [];
    let tempArlingtonSeries: (number | Date)[][] = [];
    let tempSomervilleSeries: (number | Date)[][] = [];
    let tempCambridgeSeries: (number | Date)[][] = [];

    const askArray: number[] = chartData.map((element) => {
      // if the data is member of muni, push to data series
      if (String(element.data.muni).toLowerCase() === "boston") {
        tempBostonSeries.push([element.data.postDatetime, element.data.ask]);
      } else if (String(element.data.muni).toLowerCase() === "quincy") {
        tempQuincySeries.push([element.data.postDatetime, element.data.ask]);
      } else if (String(element.data.muni).toLowerCase() === "arlington") {
        tempArlingtonSeries.push([element.data.postDatetime, element.data.ask]);
      } else if (String(element.data.muni).toLowerCase() === "somerville") {
        tempSomervilleSeries.push([element.data.postDatetime, element.data.ask]);
      } else if (String(element.data.muni).toLowerCase() === "cambridge") {
        tempCambridgeSeries.push([element.data.postDatetime, element.data.ask]);
      }
      return element.data.ask;
    });

    function median(numbers: number[]) {
      const data = Array.from(numbers).sort((a: number, b: number) => a - b);

      const middle = Math.floor(data.length / 2);

      if (data.length % 2 === 0) {
        return (data[middle - 1] + data[middle]) / 2;
      }

      return data[middle];
    }

    function groupMedianByMonthYear(series: (number | Date)[][]) {
      const tempSeriesMap = {};
      series.forEach((element: (number | Date)[]) => {
        const elementDate: Date = new Date(element[0]);
        const elementValue: number = Number(element[1]);
        const year = elementDate.getFullYear();
        const month = elementDate.getMonth();

        if (tempSeriesMap[year] === undefined) {
          tempSeriesMap[year] = {};
          if (tempSeriesMap[year][month] === undefined) {
            tempSeriesMap[year][month] = [elementValue];
          }
        } else if (tempSeriesMap[year][month] === undefined) {
          tempSeriesMap[year][month] = [elementValue];
        } else {
          tempSeriesMap[year][month].push(elementValue);
        }
      });

      const resultArray: (number | Date)[][] = [];
      // calculate median from grouped values
      Object.keys(tempSeriesMap).forEach((year) => {
        Object.keys(tempSeriesMap[year]).forEach((month) => {
          resultArray.push([new Date(Number(year), Number(month)), median(tempSeriesMap[year][month])]);
        });
      });

      return resultArray;
    }

    tempBostonSeries = groupMedianByMonthYear(tempBostonSeries);
    tempArlingtonSeries = groupMedianByMonthYear(tempArlingtonSeries);
    tempQuincySeries = groupMedianByMonthYear(tempQuincySeries);
    tempCambridgeSeries = groupMedianByMonthYear(tempCambridgeSeries);
    tempSomervilleSeries = groupMedianByMonthYear(tempSomervilleSeries);

    // set series data
    setBostonData(
      tempBostonSeries.sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      })
    );
    setArlingtonData(
      tempArlingtonSeries.sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      })
    );
    setCambridgeData(
      tempCambridgeSeries.sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      })
    );
    setQuincyData(
      tempQuincySeries.sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      })
    );
    setSomervilleData(
      tempSomervilleSeries.sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      })
    );
  }, [chartData, filters]);

  const defaultSelected = {};
  municipalities.forEach((muni) => {
    if (muni === defaultMuni) {
      defaultSelected[muni] = true;
    } else {
      defaultSelected[muni] = false;
    }
  });

  function handleCheck(select) {
    const tempSelect = [...selectedMunis];

    if (tempSelect.includes(select)) {
      tempSelect.splice(tempSelect.indexOf(select), 1);
    } else {
      tempSelect.push(select);
    }

    setSelectedMunis(tempSelect);
  }

  const legendObject = {};
  municipalities.forEach((element) => {
    legendObject[element] = selectedMunis.includes(element);
  });

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: "Median Ask by Month",
    },
    legend: {
      top: 25,
      data: ["Boston", "Cambridge", "Arlington", "Somerville", "Quincy"],
      selected: legendObject,
      selectedMode: false,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
      // boundaryGap: [0, "100%"],
    },
    series: [
      {
        type: "line",
        name: "Boston",
        data: bostonData,
        showSymbol: true,
      },
      {
        type: "line",
        name: "Cambridge",
        data: cambridgeData,
        showSymbol: true,
      },
      {
        type: "line",
        name: "Arlington",
        data: arlingtonData,
        showSymbol: true,
      },
      {
        type: "line",
        name: "Somerville",
        data: somervilleData,
        showSymbol: true,
      },
      {
        type: "line",
        name: "Quincy",
        data: quincyData,
        showSymbol: true,
      },
    ],
  };

  return (
    <>
      <ReactECharts option={option} />
      <Dropdown style={{ top: "-1rem" }}>
        <Dropdown.Toggle id="dropdown-basic">Choose Municipalities</Dropdown.Toggle>

        <Dropdown.Menu>
          <Form>
            {municipalities.map((muni) => (
              <Dropdown.Item
                key={`${muni}`}
                className="mb-3"
                id={`${muni}`}
                onClick={() => {
                  handleCheck(muni);
                }}
                active={selectedMunis.includes(muni)}
              >
                {muni}
              </Dropdown.Item>
            ))}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
