import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import React from "react";

import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";

import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";

const AggregateContainer = styled.div`
  width: 100%;
  height: 35rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: baseline;

  font-size: 16px;
`;

const AggregateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const AggregateTitles = styled.div`
  display: flex;
  flex-direction: column;

  font-weight: bold;
  width: 15rem;
`;
const AggregateValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 15rem;
  margin-left: 8rem;
`;

export default function Aggregates({
  chartData,
  filters,
  numItems,
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  numItems: React.ReactNode;
}) {
  const [minAsk, setMinAsk] = useState(0);
  const [maxAsk, setMaxAsk] = useState(0);
  const [averageAsk, setAverageAsk] = useState(0);
  const [medianAsk, setMedianAsk] = useState(0);
  const [stdAsk, setStdAsk] = useState(0);
  const [firstQuartile, setFirstQuartile] = useState(0);
  const [thirdQuartile, setThirdQuartile] = useState(0);

  useEffect(() => {
    const askArray: number[] = chartData.map((element) => element.data.ask);

    function getStandardDeviation(array) {
      // code from: https://stackoverflow.com/questions/7343890/standard-deviation-javascript
      if (array.length === 0) {
        return 0;
      }
      const n = array.length;
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
    }

    function quantile(numbers: number[], percent: number) {
      // code adapted from: https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
      const data = Array.from(numbers).sort((a: number, b: number) => a - b);

      if (percent == 0.5) {
        const middle = Math.floor(data.length / 2);

        if (data.length % 2 === 0) {
          return (data[middle - 1] + data[middle]) / 2;
        }

        return data[middle];
      }

      var pos = (data.length - 1) * percent;
      var base = Math.floor(pos);
      var rest = pos - base;
      if (data[base + 1] !== undefined) {
        return data[base] + rest * (data[base + 1] - data[base]);
      } else {
        return data[base];
      }
    }

    setMinAsk(Math.min(...askArray));
    setMaxAsk(Math.max(...askArray));
    setAverageAsk(Number((askArray.reduce((partialSum, a) => partialSum + a, 0) / askArray.length).toFixed(2)));
    setStdAsk(Number(getStandardDeviation(askArray).toFixed(2)));
    setMedianAsk(quantile(askArray, 0.5));
    setFirstQuartile(quantile(askArray, 0.25));
    setThirdQuartile(quantile(askArray, 0.75));
  }, [chartData, filters]);

  return (
    <AggregateContainer>
      <AggregateRow>
        <AggregateTitles>Num Listings:</AggregateTitles>
        <AggregateValues>{numItems} Listings</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Min ASK price:</AggregateTitles>
        <AggregateValues>${minAsk}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Max ASK price:</AggregateTitles>
        <AggregateValues>${maxAsk}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Average ASK price:</AggregateTitles>
        <AggregateValues>${averageAsk}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Standard Deviation:</AggregateTitles>
        <AggregateValues>${stdAsk}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Median ASK price:</AggregateTitles>
        <AggregateValues>${medianAsk}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>First Quartile (25%):</AggregateTitles>
        <AggregateValues>${firstQuartile}</AggregateValues>
      </AggregateRow>
      <AggregateRow>
        <AggregateTitles>Third Quartile (75%):</AggregateTitles>
        <AggregateValues>${thirdQuartile}</AggregateValues>
      </AggregateRow>
    </AggregateContainer>
  );
}
