export const municipalities = ["Arlington", "Boston", "Cambridge", "Quincy", "Somerville"];

export const dashboardURLs = {
  Boston: "https://mapc-towns.municipal.systems/collections/95e2ef12-f81a-4155-b167-a92e269a2486?data=undefined",
  Arlington: "https://mapc-towns.municipal.systems/collections/b357f512-178a-4d0c-913f-e93b15b2ba6b?data=undefined",
  Cambridge: "https://mapc-towns.municipal.systems/collections/23df5dc1-ba80-45a5-a66d-711ac88bc8f5?data=undefined",
  Somerville: "https://mapc-towns.municipal.systems/collections/b6364359-b9ba-4eb8-8862-eff211325063?data=undefined",
  Quincy: "https://mapc-towns.municipal.systems/collections/12a7518c-3e88-4a6f-a6f2-c766af87ff7b?data=undefined",
  "": "https://municipal.systems/collections/73d1cf26-8ede-4f82-b044-831f066b15cb", // MAPC users have no particular muni defined; show dashboard for all munis
};

export default municipalities;
