import { useState, useContext, useCallback, useEffect } from "react";

import { styled } from "styled-components";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { appContext } from "../context/appContext";
import { elementsArray } from "../../types/props";

import municipalities from "../components/constants/munis";
import {
  boston_neighborhoods,
  cambridge_neighborhoods,
  somerville_neighborhoods,
  quincy_neighborhoods,
  arlington_neighborhoods,
} from "../components/constants/neighborhoods";
import { dataResponseType } from "../../types/response";

const DownloadsDiv = styled.div`
  width: 100vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfffe;
  padding: 12rem;
`;

const DownloadsFormDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyleBar = styled.div`
  width: 100%;
  background-color: #004e88;
  margin-top: 1.5rem;
  height: 0.3rem;
`;

export default function Downloads() {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);

  const [ask, setAsk] = useState("");
  const [askFilter, setAskFilter] = useState("eq");
  const [numRooms, setNumRooms] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [muni, setMuni] = useState("");
  const [neighborhood, setNeighborhood] = useState("");

  const [downloadFlag, setDownloadFlag] = useState(false);
  const [url, setURL] = useState("");

  useEffect(() => {
    const updateURL = async () => {
      await fetch("/api/copy", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ask, askFilter, numRooms, updateDate, muni, neighborhood }),
      })
        .then((response) => response.json())
        .then((data) => {
          setURL(data.url);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    updateURL();
  }, [ask, askFilter, numRooms, updateDate, muni, neighborhood]);

  function generateOptions(options) {
    let muniOptions: elementsArray = [];
    options.forEach((element) => {
      muniOptions.push(
        <option value={element} key={`key-${element}`}>
          {element}
        </option>
      );
    });
    return muniOptions;
  }

  function generateFileName() {
    let fileName = "RentalListings-";

    if (ask !== "") {
      fileName = fileName + askFilter + ask + "-";
    }
    if (numRooms !== "") {
      fileName = fileName + numRooms + "-";
    }
    if (updateDate !== "") {
      fileName = fileName + updateDate + "-";
    }
    if (muni !== "") {
      fileName = fileName + muni.toUpperCase() + "-";
    }
    if (neighborhood !== "") {
      fileName = fileName + neighborhood;
    }
    if (fileName.charAt(fileName.length - 1) == "-") {
      fileName = fileName.slice(0, fileName.length - 1);
    }
    return fileName + ".json";
  }

  async function handleSubmit(e) {
    setDownloadFlag(true);
    e.preventDefault();

    await fetch("/api/proxy", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ask, askFilter, numRooms, updateDate, muni, neighborhood }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== undefined) {
          let fileToSave = new Blob([JSON.stringify(data.responseData)], { type: "application/json" });
          const url = URL.createObjectURL(fileToSave);
          const link = document.createElement("a");
          link.download = generateFileName();
          link.href = url;
          link.click();
          setDownloadFlag(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div>
      <DownloadsDiv>
        {loginStatus && (
          <DownloadsFormDiv>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Label>Filter Downloads:</Form.Label>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="($) Asking Price"
                    aria-label="Asking Price"
                    onChange={(e) => {
                      setAsk(e.target.value);
                    }}
                    className="mb-2"
                    name="ask"
                  />
                  <Form.Select
                    aria-label="Asking Filter"
                    onChange={(e) => {
                      setAskFilter(e.target.value);
                    }}
                    name="askFilter"
                  >
                    <option value="eq">{"="}</option>
                    <option value="lt">{"<"}</option>
                    <option value="gt">{">"}</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    aria-label="Number(#) of Bedrooms"
                    onChange={(e) => {
                      setNumRooms(e.target.value);
                    }}
                    name="numRooms"
                  >
                    <option>Number of Bedrooms</option>
                    <option value="0">0 Rooms</option>
                    <option value="1">1 Rooms</option>
                    <option value="2">2 Rooms</option>
                    <option value="3">3 Rooms</option>
                    <option value="4">4 Rooms</option>
                    <option value="5">5 Rooms</option>
                    <option value="6">6 Rooms</option>
                    <option value="7">7 Rooms</option>
                    <option value="8">8 Rooms</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Update Date"
                    aria-label="Date Updated"
                    onChange={(e) => {
                      setUpdateDate(e.target.value);
                    }}
                    className="mb-2"
                    name="updateDate"
                  />
                </Col>

                <Col>
                  <Form.Select
                    aria-label="Municipality"
                    onChange={(e) => {
                      setMuni(e.target.value);
                    }}
                    name="muni"
                  >
                    <option>Select Municipality</option>
                    {generateOptions(municipalities)}
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select
                    aria-label="Neighborhood"
                    onChange={(e) => {
                      setNeighborhood(e.target.value);
                    }}
                    disabled={!municipalities.includes(muni)}
                    name="neighborhood"
                  >
                    <option>Select Neighborhood</option>
                    {muni === "Boston" && generateOptions(boston_neighborhoods)}
                    {muni === "Cambridge" && generateOptions(cambridge_neighborhoods)}
                    {muni === "Somerville" && generateOptions(somerville_neighborhoods)}
                    {muni === "Quincy" && generateOptions(quincy_neighborhoods)}
                    {muni === "Arlington" && generateOptions(arlington_neighborhoods)}
                  </Form.Select>
                </Col>
                <Col style={{ display: "flex" }} className="flex-nowrap">
                  <Button
                    variant="secondary"
                    className="px-4 mx-3"
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                  >
                    Copy API
                  </Button>

                  <Button variant="primary" className=" px-4 mx-3" type="submit">
                    {!downloadFlag && "Submit"}
                    {downloadFlag && "Downloading"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </DownloadsFormDiv>
        )}
        {!loginStatus && <div>Login to access Downloads API</div>}
        <StyleBar />
      </DownloadsDiv>
    </div>
  );
}
