import { useState, useEffect, useRef, RefObject } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

import { MapContainer, TileLayer, CircleMarker, Popup, useMap } from "react-leaflet";
import * as L from "leaflet";
import "leaflet.featuregroup.subgroup";

import { styled } from "styled-components";

import municipalities from "./constants/munis";
import { proxyObject } from "../../types/response";
import { DataFilterType } from "../../types/props";

import { divIcon, Map } from "leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

require("leaflet.markercluster");

const TabContainer = styled.div`
  width: 100%;
  height: 100%;

  /* Need to use plain HTML/CSS for leaflet popups */
  div.leaflet-popup-content {
    width: 500px !important;
    ul {
      display: flex;
      list-style: none;
      flex-direction: column;
      padding-left: 0px;
      gap: 0.5rem;
      li {
        display: flex;
        strong {
          flex: 1;
        }
        span {
          flex: 3;
        }
      }
    }
  }
`;

L.Map.mergeOptions({
  // @section Mousewheel options
  // @option smoothWheelZoom: Boolean|String = true
  // Whether the map can be zoomed by using the mouse wheel. If passed `'center'`,
  // it will zoom to the center of the view regardless of where the mouse was.
  smoothWheelZoom: true,

  // @option smoothWheelZoom: number = 1
  // setting zoom speed
  smoothSensitivity: 1,
});

// @ts-ignore
L.Map.SmoothWheelZoom = L.Handler.extend({
  addHooks: function () {
    L.DomEvent.on(this._map._container, "wheel", this._onWheelScroll, this);
  },

  removeHooks: function () {
    L.DomEvent.off(this._map._container, "wheel", this._onWheelScroll, this);
  },

  _onWheelScroll: function (e) {
    if (!this._isWheeling) {
      this._onWheelStart(e);
    }
    this._onWheeling(e);
  },

  _onWheelStart: function (e) {
    var map = this._map;
    this._isWheeling = true;
    this._wheelMousePosition = map.mouseEventToContainerPoint(e);
    this._centerPoint = map.getSize()._divideBy(2);
    this._startLatLng = map.containerPointToLatLng(this._centerPoint);
    this._wheelStartLatLng = map.containerPointToLatLng(this._wheelMousePosition);
    this._startZoom = map.getZoom();
    this._moved = false;
    this._zooming = true;

    map._stop();
    if (map._panAnim) map._panAnim.stop();

    this._goalZoom = map.getZoom();
    this._prevCenter = map.getCenter();
    this._prevZoom = map.getZoom();

    this._zoomAnimationId = requestAnimationFrame(this._updateWheelZoom.bind(this));
  },

  _onWheeling: function (e) {
    var map = this._map;

    this._goalZoom = this._goalZoom + L.DomEvent.getWheelDelta(e) * 0.003 * map.options.smoothSensitivity;
    if (this._goalZoom < map.getMinZoom() || this._goalZoom > map.getMaxZoom()) {
      this._goalZoom = map._limitZoom(this._goalZoom);
    }
    this._wheelMousePosition = this._map.mouseEventToContainerPoint(e);

    clearTimeout(this._timeoutId);
    this._timeoutId = setTimeout(this._onWheelEnd.bind(this), 200);

    L.DomEvent.preventDefault(e);
    L.DomEvent.stopPropagation(e);
  },

  _onWheelEnd: function (e) {
    this._isWheeling = false;
    cancelAnimationFrame(this._zoomAnimationId);
    this._map._moveEnd(true);
  },

  _updateWheelZoom: function () {
    var map = this._map;

    if (!map.getCenter().equals(this._prevCenter) || map.getZoom() != this._prevZoom) return;

    this._zoom = map.getZoom() + (this._goalZoom - map.getZoom()) * 0.3;
    this._zoom = Math.floor(this._zoom * 100) / 100;

    var delta = this._wheelMousePosition.subtract(this._centerPoint);
    if (delta.x === 0 && delta.y === 0) return;

    if (map.options.smoothWheelZoom === "center") {
      this._center = this._startLatLng;
    } else {
      this._center = map.unproject(map.project(this._wheelStartLatLng, this._zoom).subtract(delta), this._zoom);
    }

    if (!this._moved) {
      map._moveStart(true, false);
      this._moved = true;
    }

    map._move(this._center, this._zoom);
    this._prevCenter = map.getCenter();
    this._prevZoom = map.getZoom();

    this._zoomAnimationId = requestAnimationFrame(this._updateWheelZoom.bind(this));
  },
});

// @ts-ignore
L.Map.addInitHook("addHandler", "smoothWheelZoom", L.Map.SmoothWheelZoom);

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const mapboxBaseURL = "https://api.mapbox.com/";
export const mapboxAttribution = `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`;
const createTileURL = (style) => {
  const params = new URLSearchParams();
  params.set("access_token", process.env.REACT_APP_MAPBOX_TOKEN || "");
  const stylePath = `styles/v1/mapbox/${style}/tiles/{z}/{x}/{y}/`;
  /*
  return new URL(`${mapboxBaseURL}${stylePath}?${params}`).toString();
  */
  return `${mapboxBaseURL}${stylePath}?${params}`;
};

export default function DataMap({ chartData, defaultMuni = "" }: { chartData; defaultMuni: string }) {
  const [selectedMunis, setSelectedMunis] = useState<string[]>(defaultMuni !== "" ? [defaultMuni] : municipalities);
  const [listingsByMuni, setListingsByMuni] = useState({});
  const mapRef = useRef<Map | null>(null);
  const tilesRef = useRef(null);
  const control = useRef(L.control.layers(undefined, undefined, { collapsed: false }));

  const createPopup = (listing) => {
    return `
      <div>
        <ul>
          <li>
            <strong>Title:</strong><span>${listing.title ? listing.title : listing.originalTitle}</span>
          </li>
          <li>
            <strong>Date Posted:</strong>
            <span>${months[new Date(listing.postDatetime).getMonth()] + " " + new Date(listing.postDatetime).getFullYear()}</span>
          </li>
          <li>
            <strong>Neighborhood:</strong><span>${listing.neighborhood01}</span>
          </li>
          <li>
            <strong># Beds:</strong><span>${listing.bedrooms}</span>
          </li>
          <li>
            <strong>Ask:</strong><span>$${listing.ask}</span>
          </li>
        </ul>
      </div>
		`;
  };

  useEffect(() => {
    const newListingsByMuni = {};
    chartData.forEach((l) => {
      const trimmedListing = {
        id: l.id,
        location: l.data.location,
        title: l.data.title || l.data.originalTitle,
        postDatetime: l.data.postDatetime,
        neighborhood01: l.data.neighborhood01,
        bedrooms: l.data.bedrooms,
        ask: l.data.ask,
      };
      if (newListingsByMuni[l.data.muni] == null) {
        newListingsByMuni[l.data.muni] = [trimmedListing];
      } else {
        newListingsByMuni[l.data.muni].push(trimmedListing);
      }
    });

    setListingsByMuni(newListingsByMuni);
  }, [chartData]);

  useEffect(() => {
    if (mapRef.current != null) {
      mapRef.current.eachLayer(function (layer) {
        if (layer != tilesRef.current) {
          mapRef.current?.removeLayer(layer);
        }
      });
      mapRef.current.removeControl(control.current);
      control.current = L.control.layers(undefined, undefined, { collapsed: false });

      Object.keys(listingsByMuni).forEach((muni) => {
        // @ts-ignore
        const markers = L.markerClusterGroup({ maxClusterRadius: 30 });
        const muniMarkers: any[] = [];
        listingsByMuni[muni].forEach((l) => {
          // leaflet uses lat/long, unlike GeoJSON, so swap them here
          const marker = L.marker([l.location.coordinates[1], l.location.coordinates[0]]);
          marker.bindPopup(createPopup(l));
          muniMarkers.push(marker);
          markers.addLayer(marker);
        });
        // @ts-ignore
        const muniGroup = L.featureGroup.subGroup(markers, muniMarkers);
        control.current.addOverlay(muniGroup, muni);
        muniGroup.addTo(mapRef.current);
        // @ts-ignore
        mapRef.current.addLayer(markers);
      });
      control.current.addTo(mapRef.current);
    }
  }, [mapRef, listingsByMuni]);

  const setMap = (mapRef: RefObject<Map>) => {
    // FIX REACT-LEAFLET RENDERING IN TAB AND RERENDERING
    const resizeObserver = new ResizeObserver(() => {
      mapRef.current?.invalidateSize();
    });
    const container = document.getElementById("map-container");

    if (container) {
      resizeObserver.observe(container);
    }
  };

  return (
    <TabContainer>
      <MapContainer
        id={"map-container"}
        center={[42.361145, -71.057083]}
        zoom={11}
        zoomDelta={0.25}
        maxZoom={18}
        minZoom={8}
        zoomSnap={0.25}
        zoomControl={true}
        style={{ height: "33rem", margin: "1rem 0", width: "100%" }}
        ref={mapRef}
        preferCanvas={true}
        whenReady={() => {
          setMap(mapRef);
        }}
        scrollWheelZoom={false} // disable original zoom function
        // @ts-ignore
        smoothWheelZoom={true} // enable smooth zoom
        smoothSensitivity={2.5} // zoom speed. default is 1
      >
        <TileLayer
          ref={tilesRef}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />
        <TileLayer url={createTileURL("light-v10")} attribution={mapboxAttribution} tileSize={512} zoomOffset={-1} />
      </MapContainer>
    </TabContainer>
  );
}
