import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import React from "react";

import { Form, Dropdown } from "react-bootstrap";

import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";

import municipalities from "./constants/munis";
import {
  boston_neighborhoods,
  quincy_neighborhoods,
  arlington_neighborhoods,
  cambridge_neighborhoods,
  somerville_neighborhoods,
} from "./constants/neighborhoods";
import { DataFilterType } from "../../types/props";
import { EChartsOption } from "echarts-for-react";

export default function MedianByNeighborhoodChart({
  chartData,
  filters,
  defaultMuni = "",
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  defaultMuni: string;
}) {
  const [data, setData] = useState<(string | number)[][]>([]);
  const [medianAsk, setMedianAsk] = useState(0);

  const [selectedMuni, setSelectedMuni] = useState<string>(defaultMuni);

  useEffect(() => {
    // add data to chart data array
    let tempSeries = {};

    const askArray: number[] = chartData.map((element) => {
      // if the data is member of muni, push to data series
      if (String(element.data.muni).toLowerCase() === selectedMuni.toLowerCase()) {
        if (tempSeries[element.data.neighborhood01] === undefined) {
          tempSeries[element.data.neighborhood01] = [element.data.ask];
        } else {
          tempSeries[element.data.neighborhood01].push(element.data.ask);
        }
      }
      return element.data.ask;
    });

    function median(numbers: number[]) {
      const data = Array.from(numbers).sort((a: number, b: number) => a - b);

      const middle = Math.floor(data.length / 2);

      if (data.length % 2 === 0) {
        return (data[middle - 1] + data[middle]) / 2;
      }

      return data[middle];
    }

    function groupMedianByNeighborhood(series: object) {
      const resultArray: (string | number)[][] = [];

      Object.keys(series).forEach((key: string) => {
        // key : {neighborhood: [all values]}
        resultArray.push([key, median(series[key])]);
      });

      //   return [neighborhood, median]
      return resultArray;
    }

    const result: (string | number)[][] = groupMedianByNeighborhood(tempSeries);

    setMedianAsk(median(askArray));
    // set series data
    setData(
      result.sort((a, b) => {
        const n1: string = String(a[0]).toLowerCase();
        const n2: string = String(b[0]).toLowerCase();

        if (n1 < n2) {
          return -1;
        } else if (n1 > n2) {
          return 1;
        }
        return 0;
      })
    );
  }, [chartData, filters, selectedMuni]);

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: `${selectedMuni} Median Ask by Neighborhood`,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      //   boundaryGap: false,
      data: data.map((element) => {
        return element[0];
      }),
      axisLabel: { interval: 0, rotate: 30 },
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
    },

    series: [
      {
        type: "bar",
        itemStyle: { normal: { label: { show: true } } },
        data: data.map((element) => {
          return element[1];
        }),
        markLine: {
          data: [{ name: "Median", yAxis: medianAsk }],
        },
      },
    ],
  };

  return (
    <>
      <ReactECharts option={option} />
      <Dropdown style={{ top: "-1rem" }}>
        <Dropdown.Toggle id="dropdown-basic">Choose Municipality</Dropdown.Toggle>

        <Dropdown.Menu>
          <Form>
            {municipalities.map((muni) => (
              <Dropdown.Item
                key={`${muni}`}
                className="mb-3"
                id={`${muni}`}
                onClick={() => {
                  setSelectedMuni(muni);
                }}
                active={muni === selectedMuni}
              >
                {muni}
              </Dropdown.Item>
            ))}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
