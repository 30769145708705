import { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { styled } from "styled-components";
import { Spinner } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { appContext } from "../context/appContext";
import { UserData } from "../../types/auth";
import {
  ModalContainer,
  ModalForm,
  ModalLink,
  ModalControl,
  ModalControlFeedback,
  ModalGroup,
  ModalLabel,
  ModalButton,
  ModalSubmissionStatus,
} from "../containers/ModalContainer";

export const VerifyEmail = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const [submitted, setSubmitted] = useState(false);
  const [submittedRequest, setSubmittedRequest] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const createdAt = searchParams.get("createdAt") || "0.0";
  // Consider tokens expired after 15 minutes
  const expired = (Date.now() - Number.parseFloat(createdAt)) / 1000 / 60 > 15;

  useEffect(() => {
    // If user is already verified and still arrived here somehow, just bring them to home
    if (userSettings != null && !submitted && userSettings.verified) {
      navigate("/");
    }
    // If user just submitted verification and it was successful
    if (userSettings != null && submitted && userSettings.verified) {
      // Show success message, wait a moment, then navigate to home
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
    // If we're already logged in, go to the homepage
    if (!expired && !submitted) {
      submitVerification();
    }
  });

  const submitVerification = async () => {
    const token = searchParams.get("token");
    if (expired || token == null) {
      return;
    }
    const response = await fetch(`/api/verify-email/${token}`);

    const data = await response.json();

    if (data != null && data.ok && data.userSession != null) {
      setUserSettings(data.userSession);
      setLoginStatus(true);
    }
    setSubmitted(true);
  };

  const requestVerification = async () => {
    const response = await fetch(`/api/request-email-verification`);
    setSubmittedRequest(true);
  };

  return (
    <ModalContainer title="E-mail Verification">
      {createdAt !== "0.0" && !submitted && !expired && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {submittedRequest && (
        <>
          <p>A new account verification e-mail has been sent to the e-mail address you provided.</p>
          <p>Please follow the instructions in that e-mail to verify your account.</p>
          <p>Account verification e-mails are valid for 15 minutes from the time they are requested.</p>
          <p>
            If you did not receive an account verification e-mail, or are otherwise unable to locate it, contact us at{" "}
            <a href="mailto:digitalservices@mapc.org">digitalservices@mapc.org</a>.
          </p>
        </>
      )}
      {!submittedRequest && createdAt !== "0.0" && expired && (
        <>
          <p>This e-mail verification link has expired.</p>
          <ModalButton onClick={requestVerification}>Request a new e-mail verification link</ModalButton>
        </>
      )}
      {!submittedRequest && createdAt === "0.0" && !submitted && (
        <>
          <p>An account verification e-mail has been sent to the e-mail address you provided.</p>
          <p>Please follow the instructions in that e-mail to verify your account.</p>
          <p>Account verification e-mails are valid for 15 minutes from the time they are requested.</p>
          <p>If you did not receive an account verification e-mail, or are otherwise unable to locate it, use the button below to request a new one.</p>
          <ModalButton onClick={requestVerification}>Request a new e-mail verification link</ModalButton>
        </>
      )}
      {createdAt !== "0.0" && submitted && !loginStatus && !expired && (
        <p>
          We encountered an unexpected error when verifying your account. Please <a href="/api/request-email-verification">click here</a> to request a new
          account verification e-mail, or contact us at <a href="mailto:digitalservices@mapc.org">digitalservices@mapc.org</a>.
        </p>
      )}
      {userSettings != null && submitted && userSettings.verified && (
        <p>E-mail address successfully verified. You will be returned to the home page in just a moment.</p>
      )}
    </ModalContainer>
  );
};

export default VerifyEmail;
