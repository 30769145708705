import { useState, useContext, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import { styled } from "styled-components";

import { appContext } from "../context/appContext";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import Table from "react-bootstrap/Table";
import { Breadcrumb, Spinner } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import { documentationObject, documentationResponse } from "../../types/response";

const DocDiv = styled.div`
  width: 100vw;
  height: 40rem;
  display: flex;
  flex-direction: row;
  background-color: #fbfffe;

  thead > tr > th {
    /* Using bg color from bootstrap's Card "Cap" */
    background-color: rgba(33, 37, 41, 0.03);
  }
`;

const DocLeftDiv = styled.div`
  flex: 1;
  min-width: 20%;
  background: #e2f4ff;
  height: 100%;
  padding: 1rem 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  display: inline;
  justify-content: center;

  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #68a4dd;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fbfffe;
    margin: 0.1rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e2f4ff;
  }
`;

const DocRightDiv = styled.div`
  width: 90%;
  /* background: green; */
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem;
  overflow-y: scroll;
`;

const ListGroupItem = styled(ListGroup.Item)`
  background-color: #fbfffe;
  &:hover {
    background-color: ${(props) => (props.active ? "#0d6efd" : "#f1f9ff")};
  }
`;

export default function Documentation() {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);

  const [activeDocument, setActiveDocument] = useState("");
  const [documents, setDocuments] = useState<{ [key: string]: { Body: string; Status: string } }>({});
  const [fetchedDocuments, setFetchedDocuments] = useState<boolean>(false);

  const PartialTable = useCallback((props) => <Table bordered hover {...props} />, []);

  useEffect(() => {
    if (!fetchedDocuments) {
      const fetchData = async () => {
        await fetch(`/api/documentation`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data: documentationResponse) => {
            if (data !== undefined) {
              const documentMap: { [key: string]: { Body: string; Status: string } } = {};
              data["documents"].forEach((element: documentationObject) => {
                documentMap[element.Field] = { Body: element.Body, Status: element.Status };
              });
              setDocuments(documentMap);
              setActiveDocument(data["documents"][0].Field);
            }
          });
      };

      fetchData();
      setFetchedDocuments(true);
    }
  }, [fetchedDocuments]);

  return (
    <div>
      <DocDiv>
        <DocLeftDiv>
          {Object.keys(documents).length === 0 && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <ListGroup defaultActiveKey={activeDocument}>
            {Object.keys(documents).map((key) => (
              <ListGroupItem
                action
                key={key}
                onClick={() => {
                  setActiveDocument(key);
                }}
                active={activeDocument === key}
              >
                {key}
              </ListGroupItem>
            ))}
          </ListGroup>
        </DocLeftDiv>
        <DocRightDiv>
          {Object.keys(documents).length !== 0 && activeDocument !== "" && (
            <>
              <h2>{<Markdown remarkPlugins={[remarkGfm]}>{activeDocument}</Markdown>}</h2>
              <Markdown components={{ table: PartialTable }} remarkPlugins={[remarkGfm]}>
                {documents[activeDocument].Body}
              </Markdown>
            </>
          )}
        </DocRightDiv>
      </DocDiv>
    </div>
  );
}
