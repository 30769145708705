import { useState } from "react";
import { Link } from "react-router-dom";

import { styled } from "styled-components";

const logo: string = require("../assets/mapc-logo.svg").default;

const FooterDiv = styled.div`
  width: 100vw;
  height: 7.5rem;
  background-color: rgba(0, 54, 94, 1);
  color: #fbfffe;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 100vh;
`;

const FooterLeftDiv = styled.div`
  width: 49.5%;
  padding: 2.25rem 2.5rem;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
`;
// const VBar = styled.div`
//   background-color: #fbfffe;
//   width: 2px;
//   height: 6rem;
//   margin-top: 1rem;
// `;

const DetailsDiv = styled.div`
  color: #fbfffe;
  display: flex;
  flex-direction: row;
`;

const DetailsUl = styled.ul`
  list-style: none;
  margin: -0.625rem 0.75rem;
`;

const DetailsLi = styled.li`
  margin: 0rem 0 0;
  text-decoration: none;
`;

const DetailLink = styled(Link)`
  color: #fbfffe;
`;

const DetailLinkDiv = styled.div`
  color: #fbfffe;
`;

const DetailAnchor = styled.a`
  text-decoration: none;
  color: #fbfffe;
`;

const FooterRightDiv = styled.div`
  width: 49.5%;
  padding: 1.1rem 2.5rem;
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const RightVertDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const RightHorizDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightLinksHeader = styled.strong`
  margin-left: 0.75rem;
  margin-top: -0.25rem;
`;

export default function Footer() {
  return (
    <FooterDiv id={"footer"}>
      <FooterLeftDiv>
        <a href="https://mapc.org/">
          <img src={logo} height={55} alt="MAPC Logo" />
        </a>
        <DetailsDiv>
          <DetailsUl>
            <DetailsLi>
              <DetailAnchor href="https://goo.gl/maps/5GP7YcEtS1E2">
                <strong>60 Temple Place, Boston, MA 02111</strong>
              </DetailAnchor>
            </DetailsLi>
            <DetailsLi>
              <DetailAnchor href="tel:617-933-0700">617-933-0700</DetailAnchor>
            </DetailsLi>
            <DetailsLi>
              <DetailAnchor href="mailto:rentallistings@mapc.org">rentallistings@mapc.org</DetailAnchor>
            </DetailsLi>
          </DetailsUl>
        </DetailsDiv>
      </FooterLeftDiv>
      <FooterRightDiv>
        <DetailsDiv>
          <RightVertDiv>
            <RightLinksHeader>Pages:</RightLinksHeader>
            <RightHorizDiv>
              <DetailsUl style={{ paddingTop: "0.75rem", paddingLeft: 0 }}>
                <DetailsLi>
                  <DetailLink to="/#about">
                    <DetailLinkDiv>About Us</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>

                <DetailsLi>
                  <DetailLink to="/documentation">
                    <DetailLinkDiv>Documentation</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                <DetailsLi>
                  <DetailLink to="/data">
                    <DetailLinkDiv>Data</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
              </DetailsUl>
              <DetailsUl style={{ paddingTop: "0.75rem" }}>
                <DetailsLi>
                  <DetailLink to="/login">
                    <DetailLinkDiv>Login</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                <DetailsLi>
                  <DetailLink to="/register">
                    <DetailLinkDiv>Register</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                <DetailsLi>
                  <DetailLink to="/password-reset-request">
                    <DetailLinkDiv>Forgot Password</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
              </DetailsUl>
            </RightHorizDiv>
          </RightVertDiv>
        </DetailsDiv>
      </FooterRightDiv>
    </FooterDiv>
  );
}
