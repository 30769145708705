import { useCallback } from "react";
import { styled } from "styled-components";

import { Link } from "react-router-dom";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import { ButtonProps, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


const ParticlesHero = styled(Particles)`
  width: 100%;
  height: 100%;
`;

const HeroDiv = styled.div`
  width: 100vw;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  position: absolute;

  width: 30vw;
  height: fit-content;
  max-height: 85%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12.5px;
  padding: 2rem;
  background-color: #fbfffe;
  color: #635c7b;
  box-shadow: 0px 0px 10px 3px #2b3b5e;
`;

const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 1rem;
`;

export const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ModalLink = styled(Link)`
  color: #635c7b;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 0.5rem;
  text-decoration: underline;
`;

export const ModalControl = styled(Form.Control)`
  margin-bottom: 0.5rem;
`;

export const ModalControlFeedback = styled(Form.Control.Feedback)``;

export const ModalGroup = styled(Form.Group)`
  margin-bottom: 2rem;
`;

export const ModalLabel = styled(Form.Label)`
  font-weight: bold;
`;

export const ModalGroupLabel = styled(Form.Label)`
  font-weight: bold;
	text-decoration: underline;
`;

// Need to explicitly type this button
// See https://github.com/styled-components/styled-components/issues/4166
export const ModalButton: FC<ButtonProps> = styled(Button)`
  background: #219af1;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: #0c70b8 !important;
  }
  &:active {
    background-color: #084e81 !important;
  }
`;

export const ModalSubmitButton = ({isSubmitting, type="submit", label, ...props}) => {
  // Need to manually adjust padding since spinner does not get 
  return (<ModalButton 
    type={type} 
    disabled={isSubmitting}
    {...props}
  >
    {isSubmitting && 
      <Spinner as="span" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    }
    {!isSubmitting? label: ""}
  </ModalButton>)
};

export const ModalSubmissionStatus = styled.div`
  color: #dc3545;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ModalContainer = ({ title = "", children }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <HeroDiv>
      <ParticlesHero
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: { enable: false },
          fpsLimit: 60,
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 4 },
            },
          },
        }}
      />
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        {children}
      </ModalContent>
    </HeroDiv>
  );
};

export default ModalContainer;
