import { useState, useContext, useCallback, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { styled } from "styled-components";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import {
  ModalContainer,
  ModalForm,
  ModalLink,
  ModalControl,
  ModalControlFeedback,
  ModalGroup,
  ModalLabel,
  ModalSubmitButton,
  ModalSubmissionStatus,
} from "../containers/ModalContainer";

export const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [requested, setRequested] = useState<Boolean | null>(null);
  const [requestError, setRequestError] = useState<Boolean | null>(null);
  const createdAt = searchParams.get("createdAt") || "0.0";
  // Consider tokens expired after 15 minutes
  const expired = (Date.now() - Number.parseFloat(createdAt)) / 1000 / 60 > 15;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required()
      .label("Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
        "Password must be at least 7 characters long, and must contain a mix of numbers and uppercase, lowercase, and special characters."
      ),
    passwordConfirmation: yup
      .string()
      .required()
      .label("Password confirmation")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const submitReset = async (values, actions) => {
    const token = searchParams.get("token");
    const response = await fetch(`/api/password-reset/${token}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...values }),
    });

    const data = await response.json();

    if (data != null && data.ok) {
      // Message indicating password reset success, link to login
      setRequested(true);
    } else if ((data != null && !data.ok) || data == null) {
      // Message indicating password reset failure
      setRequested(false);
    }
  };

  return (
    <ModalContainer title="Reset Password">
      <Formik validateOnBlur validationSchema={schema} onSubmit={submitReset} initialValues={initialValues}>
        {({ handleSubmit, handleBlur, handleChange, values, touched, errors, isSubmitting }) => (
          <ModalForm noValidate onSubmit={handleSubmit}>
            {requested && (
              <p className={"mb-0"} style={{ width: "100%", textAlign: "center" }}>
                Password updated successfully.
                <br />
                <br />
                <ModalLink to="/login">Login</ModalLink>
              </p>
            )}
            {(expired || (expired && requestError)) && (
              <p className={"mb-0"} style={{ width: "100%", textAlign: "center" }}>
                This password reset request has expired, please make a new password reset request if you still wish to reset your password.
                <br />
                <br />
                <ModalLink to="/password-reset-request">Make a new password reset request</ModalLink>
              </p>
            )}
            {((requested != null && !requested && !expired) || requestError) && (
              <p className={"mb-0"} style={{ width: "100%", textAlign: "center" }}>
                We encountered an issue changing your password.
                <br />
                <br />
                This issue has been logged and we will work to resolve it. Please try again later, or contact us at digitalservices@mapc.org if further password
                resets are unsuccessful.
              </p>
            )}
            {(requested == null || (requested != null && !requested)) && !expired && (
              <>
                <ModalGroup>
                  <ModalLabel>New Password</ModalLabel>
                  <ModalControl
                    required
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                    placeholder="Create a password"
                  />
                  <ModalControlFeedback type="invalid">
                    <ErrorMessage name="password" />
                  </ModalControlFeedback>
                  <ModalLabel>Confirm New Password</ModalLabel>
                  <ModalControl
                    required
                    type="password"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                    placeholder="Confirm password"
                  />
                  <ModalControlFeedback type="invalid">
                    <ErrorMessage name="passwordConfirmation" />
                  </ModalControlFeedback>
                </ModalGroup>
                <ModalSubmitButton isSubmitting={isSubmitting} label="Change Password" />
              </>
            )}
          </ModalForm>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default PasswordReset;
