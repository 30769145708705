import { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import {
  ModalContainer,
  ModalForm,
  ModalLink,
  ModalControl,
  ModalControlFeedback,
  ModalGroup,
  ModalLabel,
  ModalSubmitButton,
  ModalSubmissionStatus,
} from "../containers/ModalContainer";

export const PasswordResetRequest = () => {
  const [requested, setRequested] = useState<Boolean | null>(null);
  const [requestError, setRequestError] = useState<Boolean | null>(null);

  const schema = yup.object().shape({
    email: yup.string().email().trim().required().label("E-mail"),
  });

  const initialValues = { email: "" };

  const submitRequest = async (values, actions) => {
    const response = await fetch("/api/request-password-reset", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...values }),
    });

    const data = await response.json();

    if (data != null && data.ok) {
      // Provide further instructions
      setRequested(true);
    } else if (data != null && !data.ok) {
      // Message indicating e-mail delivery failure
      setRequested(false);
    } else if (data == null) {
      // Message indicating server failure
      setRequestError(false);
    }
  };

  return (
    <ModalContainer title="Request Password Reset">
      <Formik validateOnBlur validationSchema={schema} onSubmit={submitRequest} initialValues={initialValues}>
        {({ handleSubmit, handleBlur, handleChange, values, touched, errors, isSubmitting }) => (
          <ModalForm noValidate onSubmit={handleSubmit}>
            {requested && (
              <p>
                Password reset request submitted successfully.
                <br />
                <br />
                If the e-mail address provided is linked to an active account, you will receive an e-mail with further instructions on how to reset your
                password.
              </p>
            )}
            {requested != null && !requested && (
              <ModalSubmissionStatus>
                We encountered an issue delivering the password reset e-mail.
                <br />
                <br />
                This issue has been logged and we will work to resolve it. Please try again later, or contact us at{" "}
                <a href="mailto:digitalservices@mapc.org">digitalservices@mapc.org</a> if further password reset requests continue to fail to be sent.
              </ModalSubmissionStatus>
            )}
            {requestError && (
              <ModalSubmissionStatus>
                We were unable to process your password reset request due to an unexpected error.
                <br />
                <br />
                This issue has been logged and we will work to resolve it. Please try again later, or contact us at{" "}
                <a href="mailto:digitalservices@mapc.org">digitalservices@mapc.org</a> if further password reset requests continue to fail to be sent.
              </ModalSubmissionStatus>
            )}
            {requested == null && (
              <>
                <ModalGroup>
                  <ModalLabel>Email address</ModalLabel>
                  <ModalControl
                    required
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                    placeholder="Enter your e-mail address"
                  />
                  <ModalControlFeedback type="invalid">
                    <ErrorMessage name="email" />
                  </ModalControlFeedback>
                </ModalGroup>
                <ModalSubmitButton isSubmitting={isSubmitting} label="Request Password Reset E-Mail" />
              </>
            )}
          </ModalForm>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default PasswordResetRequest;
