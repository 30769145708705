import { useContext } from "react";

import Hero from "../components/Hero";
import About from "../components/About";
import { appContext } from "../context/appContext";
import FeedbackCarousel from "../components/FeedbackCarousel";

function AboutPage() {
  const { loginStatus } = useContext(appContext);

  return (
    <div>
      <Hero />
      <About loginStatus={!!loginStatus} />
      <FeedbackCarousel></FeedbackCarousel>
    </div>
  );
}

export default AboutPage;
