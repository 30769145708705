import { useState, useContext, useCallback, useEffect, FC } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { appContext } from "../context/appContext";
import {
  ModalContainer,
  ModalForm,
  ModalLink,
  ModalControl,
  ModalControlFeedback,
  ModalGroup,
  ModalLabel,
  ModalSubmitButton,
  ModalSubmissionStatus,
} from "../containers/ModalContainer";

export const Login = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email().trim().required().label("E-mail"),
    password: yup.string().required().label("Password"),
  });

  const initialValues = { email: "", password: "" };

  useEffect(() => {
    // If we're already logged in, go to the homepage
    if (loginStatus) {
      navigate("/");
    }
  }, [loginStatus]);

  const submitLogin = async (values, actions) => {
    const response = await fetch("/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: values.email, password: values.password }),
    });

    const data = await response.json();
    if (data != null && data.ok && data.userSession != null) {
      setUserSettings(data.userSession);
      setLoginStatus(true);
    }
    setSubmitted(true);
  };

  return (
    <ModalContainer title="Log In">
      <Formik validateOnBlur validationSchema={schema} onSubmit={submitLogin} initialValues={initialValues}>
        {({ handleSubmit, handleBlur, handleChange, values, touched, errors, isSubmitting }) => (
          <ModalForm noValidate onSubmit={handleSubmit}>
            <ModalGroup>
              <ModalLabel>E-mail address</ModalLabel>
              <ModalControl
                required
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                placeholder="Enter e-mail"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="email" />
              </ModalControlFeedback>
            </ModalGroup>
            <ModalGroup>
              <ModalLabel>Password</ModalLabel>
              <ModalControl
                required
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
                placeholder="Password"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="password" />
              </ModalControlFeedback>
            </ModalGroup>
            {!isSubmitting && submitted && !loginStatus && (
              <ModalSubmissionStatus>
                Login not successful: please check your e-mail and password and try again.
                <br />
                <br />
                If you do not yet have an account, or do not know your password, please use the links below to proceed.
              </ModalSubmissionStatus>
            )}
            <ModalSubmitButton isSubmitting={isSubmitting} label="Log In" />
            <ModalLink to="/register">Register</ModalLink>
            <ModalLink to="/password-reset-request">Forgot password?</ModalLink>
          </ModalForm>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default Login;
