import { useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { styled } from "styled-components";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { PersonFill } from "react-bootstrap-icons";

import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

import { appContext } from "../context/appContext";
import { NavPropType } from "../../types/props";
import { UserData } from "../../types/auth";

const logo = require("../assets/mapc-logo.svg");

const NavContainer = styled.div`
  background-color: #fbfffe;
  width: 100vw;
  padding: 0.5rem 2.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 1px 20px 1px #2b3b5e;
  border-bottom: 1px solid #adb5bd;

  a,
  a:visited,
  a:hover {
    color: #635c7b;
    text-decoration: none;
    font-weight: bold;
  }
`;

const NavLeft = styled.a`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: fit-content;
`;

const TitleLink = styled.h1`
  font-size: 24px;
  font-family: sans-serif;
  margin-bottom: 0px;
  font-weight: bold;
`;

const NavRight = styled(Nav)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

const NavLink = styled(Nav.Link)`
  text-decoration: none;
  font-family: sans-serif;
`;

const SiteLink = styled(Link)`
  text-decoration: none;
  font-family: sans-serif;
`;

const ProfileDiv = styled.div`
  background-color: #219af1;
  border-radius: 5rem;
  width: 2.5rem;
  height: 2.5rem;
  pointer-events: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #004e88;
  }

  svg {
    fill: white;
  }
`;

const OverlayDiv = styled.div`
  background-color: #dceef6;
  width: 20vw;
  height: 85vh;
  position: absolute !important;
  top: 0.75rem !important;
  right: 5rem !important;

  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px #2b3b5e;
  z-index: 1000;
`;

const OverlayTitle = styled.h4`
  color: #635c7b;
  font-weight: bold;
`;

const OverlayStyleBar = styled.div`
  width: 100%;
  background-color: #004e88;
  margin-top: 0.5rem;
  height: 0.15rem;
`;

const OverlayUl = styled.ul`
  padding: 0;
  margin: 0;
`;
const OverlayLi = styled.li`
  text-decoration: none;
  margin: 1rem 0;
  list-style: none;
  font-weight: bold;
  text-wrap: none;
`;

const OverlayIcon = styled.img`
  margin-right: 0.25rem;
`;

const OverlayAnchor = styled.div`
  text-decoration: none;
  color: #7e7697;
`;

export default function NavBar() {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const [showProfile, setShowProfile] = useState(false);
  const target = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  async function handleLogout(e) {
    e.preventDefault();

    await fetch("/api/logout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok === true) {
          setLoginStatus(false);
          setUserSettings(null);
          setShowProfile(false);
          navigate("/");
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <NavContainer>
      <NavLeft href="/">
        <TitleLink>Rental Listings Database</TitleLink>
      </NavLeft>
      <NavRight variant="underline" activeKey={location.pathname.slice(1)}>
        <Nav.Item>
          <NavLink eventKey="analyses" href="/analyses">
            Analyses
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink eventKey="documentation" href="/documentation">
            Documentation
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink eventKey="data" href="/data">
            Data
          </NavLink>
        </Nav.Item>

        {loginStatus ? (
          <ProfileDiv
            ref={target}
            onClick={() => {
              setShowProfile(!showProfile);
            }}
          >
            <PersonFill size="25" />
          </ProfileDiv>
        ) : (
          <Nav.Item>
            <NavLink eventKey="login" href="/login">
              Login
            </NavLink>
          </Nav.Item>
        )}

        <Overlay
          target={target.current}
          show={showProfile}
          placement="bottom"
          rootClose
          transition={false}
          onHide={() => {
            setShowProfile(!showProfile);
          }}
        >
          {({ placement: _placement, arrowProps: _arrowProps, show: _show, popper: _popper, hasDoneInitialMeasure: _hasDoneInitialMeasure, ...props }) => (
            <OverlayDiv {...props}>
              <OverlayTitle>{userSettings !== null && userSettings.name}</OverlayTitle>
              <OverlayStyleBar />
              <OverlayUl>
                <OverlayLi>
                  <SiteLink
                    to="/data"
                    onClick={() => {
                      setShowProfile(!showProfile);
                    }}
                  >
                    <OverlayAnchor>Data</OverlayAnchor>
                  </SiteLink>
                </OverlayLi>
                <OverlayLi>
                  <SiteLink
                    to="/analyses"
                    onClick={() => {
                      setShowProfile(!showProfile);
                    }}
                  >
                    <OverlayAnchor>Analyses</OverlayAnchor>
                  </SiteLink>{" "}
                </OverlayLi>
                <OverlayLi>
                  <SiteLink
                    to="/documentation"
                    onClick={() => {
                      setShowProfile(!showProfile);
                    }}
                  >
                    <OverlayAnchor>Documentation</OverlayAnchor>
                  </SiteLink>
                </OverlayLi>
              </OverlayUl>
              <Button onClick={handleLogout}>Logout</Button>
            </OverlayDiv>
          )}
        </Overlay>
      </NavRight>
    </NavContainer>
  );
}
