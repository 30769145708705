import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import NavBar from "./components/Nav";
import Footer from "./components/Footer";
import { appContext } from "./context/appContext";

import { userSettingsProps } from "../types/props";

export const App = () => {
  // toggle loginStatus here for dev testing
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<userSettingsProps | null>(null);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    // If we have an account but we're not verified
    if (userSettings != null && !userSettings.verified && token == null) {
      // Redirect to verification page
      navigate("/verify-email");
      return;
    }
    // If we're already logged in, skip
    if (userSettings != null && loginStatus) {
      return;
    }
    // Try to restore logged-in state and user info from session if it's available
    const session = async () => {
      const response = await fetch("/api/session", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        cache: "no-cache",
      });
      const data = await response.json();
      if (data.ok === true && data.user) {
        const settings = {
          id: data.user.id,
          email: data.user.email,
          name: data.user.name,
          muni: data.user.muni,
          userType: data.user.userType,
          isPublic: data.user.isPublic,
          verified: data.user.verified,
        };
        setUserSettings(settings);
        setLoginStatus(true);
      } else {
        // Session has expired or doesn't exist, prompt login
        setUserSettings(null);
        setLoginStatus(false);
      }
    };

    session();
  }, [userSettings, loginStatus]);

  return (
    <appContext.Provider
      value={{
        loginStatus,
        setLoginStatus,
        userSettings,
        setUserSettings,
      }}
    >
      <NavBar />
      <Outlet />
      <Footer />
    </appContext.Provider>
  );
};

export default App;
