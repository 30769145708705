import { useState, useContext, useCallback, useEffect } from "react";

import { styled } from "styled-components";

import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

import Form from "react-bootstrap/Form";

import { elementsArray } from "../../types/props";
import { memberObject, memberResponse } from "../../types/response";
import municipalities from "../components/constants/munis";

import { appContext } from "../context/appContext";

const MemberHeroDiv = styled.div`
  width: 100vw;
  height: 7.5vh;
  display: flex;
  flex-direction: column;
`;

const MembersDiv = styled.div`
  width: 100vw;
  height: 77.5vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfffe;
  padding: 4rem 10rem 2.5rem 10rem;
`;

const MembersFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  padding: 1rem 1rem 1rem 0rem;
  color: #635c7b;
`;

const MembersContainerDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const MembersContainerTopDiv = styled.div`
  width: 100%;
  padding: 0 2.75rem 0 1rem;
  background-color: #ddf4ff;
  border-radius: 5px 5px 0px 0px;
`;

const MembersContainerBodyDiv = styled.div`
  width: 100%;
  height: 85%;

  background-color: #b9e2ff;
  border-radius: 0px 0px 5px 5px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
  border-color: #b9e2ff;
  border-width: 0.4rem;

  padding: 1rem;

  overflow-x: none;
  overflow-y: scroll;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #68a4dd;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fbfffe;
    margin: 0.1rem
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e2f4ff;
    
  }
`;

const MembersList = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  color: #635c7b;
  padding: 1.5rem;
  justify-content: start;
  align-items: center;
`;

const MembersListItem = styled(MembersList)`
  background-color: #fbfffe;
  margin-bottom: 0.65rem;
  &:hover {
    background-color: #ddf4ff;
  }
`;

const MembersListLeft = styled.div`
  font-weight: bold;
  margin-left: 2rem;
`;

const MembersListRight = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-grow: 1;
  justify-content: end;
  margin-right: 2rem;
`;

const MembersListRightItem = styled.div`
  text-decoration: none;
  width: 9rem;
  margin: 0 1rem;
`;

const MembersListRightLink = styled.a`
  text-decoration: none;
  color: #3e54d4;
`;

const MemberHero = styled.div`
  width: 100vw;
  height: 7.5vh;
  display: flex;
  flex-direction: column;
`;

const MemberHeroText = styled.div`
  width: 100%;

  position: absolute;
  top: 6vh;
  color: #fbfffe;
  margin: 0;
  /* top: 2.75rem; */
  font-size: 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* margin: -4rem 0; */
  align-items: center;
`;

const ParticlesHero = styled(Particles)`
  width: 100vw;
  height: 7.5vh;
  pointer-events: none;
`;

export default function Members() {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);

  const [sortMethod, setSortMethod] = useState("asc");
  const [muni, setMuni] = useState("Municipality");
  const [members, setMembers] = useState<memberObject[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch("/api/members", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== undefined) {
            setMembers(data.membersList);
          }
        })
        .then((err) => {
          console.error(err);
        });
    };

    fetchData();
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  function generateMembers() {
    const tempArray: elementsArray = [];

    members.sort((a, b) => {
      if (sortMethod === "asc") {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      } else if (sortMethod === "desc") {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        return 0;
      }
      return 0;
    });

    members.forEach((element) => {
      if (muni === "Municipality" || element.muni === muni) {
        tempArray.push(
          <MembersListItem>
            <MembersListLeft>{element.name}</MembersListLeft>
            <MembersListRight>
              <MembersListRightItem>{element.muni}</MembersListRightItem>
              <MembersListRightItem>
                <MembersListRightLink href={`mailto:${element.contact}`}>{element.contact}</MembersListRightLink>
              </MembersListRightItem>
            </MembersListRight>
          </MembersListItem>
        );
      }
    });

    return tempArray;
  }

  function generateOptions() {
    let muniOptions: elementsArray = [];
    municipalities.forEach((element) => {
      muniOptions.push(
        <option value={element} key={`key-${element}`}>
          {element}
        </option>
      );
    });
    return muniOptions;
  }

  return (
    <div>
      <MemberHeroDiv>
        <MemberHero>
          <ParticlesHero
            id="tsparticles"
            init={particlesInit}
            options={{
              fullScreen: { enable: false },
              fpsLimit: 60,
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 4 },
                },
              },
            }}
          />
        </MemberHero>
        {loginStatus && (
          <MemberHeroText>
            <strong>Members</strong>
          </MemberHeroText>
        )}
        {!loginStatus && (
          <MemberHeroText>
            <strong>Please Log In</strong>
          </MemberHeroText>
        )}
      </MemberHeroDiv>
      {loginStatus && (
        <MembersDiv>
          <MembersFilterDiv>
            <strong className="mx-2 pt-2">FILTERS</strong>

            <Form.Select
              aria-label="Filter"
              className="mx-2"
              onChange={(e) => {
                setMuni(e.target.value);
              }}
            >
              <option>Municipality</option>
              {generateOptions()}
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="mx-2"
              onChange={(e) => {
                setSortMethod(e.target.value);
              }}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Form.Select>
          </MembersFilterDiv>
          <MembersContainerDiv>
            <MembersContainerTopDiv>
              <MembersList>
                <MembersListLeft>Name</MembersListLeft>
                <MembersListRight>
                  <MembersListRightItem>
                    <strong>Municipality</strong>
                  </MembersListRightItem>
                  <MembersListRightItem>
                    <strong>Contact</strong>
                  </MembersListRightItem>
                </MembersListRight>
              </MembersList>
            </MembersContainerTopDiv>
            <MembersContainerBodyDiv>{generateMembers()}</MembersContainerBodyDiv>
          </MembersContainerDiv>
        </MembersDiv>
      )}
    </div>
  );
}
